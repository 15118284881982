
import { Button } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import React, { useEffect } from 'react';
import { SnackbarProvider } from "notistack";
import { ApolloProvider } from '@apollo/client';
import { Fab } from '@mui/material';
import { Route, Routes } from "react-router-dom";
import './App.css';
import { MySnackMessage } from "./component/Misc/MySnackMessage";
import { PATH } from "./config/routes";
import { LogOutLayout } from './layout/LogoutLayout';
import { TwoColumnLayout } from "./layout/TwoColumnLayout";
import { TwoRowLayout } from "./layout/TwoRowLayout";
import { MyNFTBox } from "./page/staking/MyNFTBox";
import { AirdropAlphaTestResult } from "./page/staking/AirdropAlphaTestResult";
import { ClaimVesting } from "./page/staking/ClaimVesting";
import { Partner } from "./page/staking/Partner";
import { TypeBoxTest } from "./page/staking/TypeBoxTest";
import { Staking } from "./page/staking/Staking";
import sessionData from './sessionData';
import ThemeConfig from "./theme";
import GlobalStyles from './theme/globalStyles';
import { NETWORK_VERSION,WALLETCONNECT_PROVIDER } from './util/constants';
import { getNetworkVersion, getProviderType, getWeb3, initWallet, switchToBSCNetwork } from './util/cryptoWallet';
import history from './util/myHistory';
import { setPool } from './service/staking';
import { WhiteList } from './page/staking/WhiteList';
import { WhitelistResult } from './page/staking/WhitelistResult';
import { Ranking } from './page/staking/Ranking';
import { Sapphire } from './page/staking/Sapphire';
import { client } from './util/apollo';
import { isMobileBrowser } from './util/utils';
sessionData.getSessionData()

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
}

//setPool()

function App() {

    useEffect(() => {
    initWallet()
    if (sessionData.userId) {
      if (getNetworkVersion() != NETWORK_VERSION) {
        switchToBSCNetwork(history)
      } else {
        //update lại address và request quyền
        //requestAdressAndApproveToken()
      }
    }
    }, []);
    

    useEffect(() => {
    window.addEventListener("load", () => {
      const _web3 = getWeb3()

      if (!_web3) return
      
      _web3.currentProvider.on('accountsChanged', async () => {
        sessionData.logOut()
        if (getProviderType() == WALLETCONNECT_PROVIDER) {
          await _web3.currentProvider.disconnect()
        }
      });

      _web3.currentProvider.on('networkChanged', async (networkId) => {
        if (networkId !== NETWORK_VERSION) {
          sessionData.logOut()
          if (getProviderType() == WALLETCONNECT_PROVIDER) {
            await _web3.currentProvider.disconnect()
          }
        }
      });

      _web3.currentProvider.on("disconnect", async () => {
        sessionData.logOut()
        if (getProviderType() == WALLETCONNECT_PROVIDER) {
          await _web3.currentProvider.disconnect()
        }
      });
    });
  }, []);

    return (
        <ThemeConfig>
            <GlobalStyles/>
              <ApolloProvider client={client}>
                <SnackbarProvider
                    ref={notistackRef}
                    action={(key) => (
                        <Button onClick={onClickDismiss(key)} size="small" color="inherit" variant="outlined">
                            Dismiss
                        </Button>
                    )}
                    content={
                        (key, message) => {
                            if (typeof message === 'string' || message instanceof String) return undefined
                            return (<MySnackMessage id={key} message={message}/>)
                        }}
                    maxSnack={3} preventDuplicate>
                    <Routes>
                        <Route path={PATH.HOME.path} element={<TwoRowLayout/>}>
                            <Route path={PATH.STAKING.path} element={<TwoColumnLayout/>}>
                                <Route index element={<Sapphire/>}/>
                                <Route path={PATH.STAKING_ME.path} element={<MyNFTBox/>}/>
                                <Route path={PATH.WHITE_LIST.path} element={<WhiteList/>}/>
                                <Route path={PATH.WHITE_LIST_RANKING.path} element={<Ranking/>} />
                                <Route path={PATH.WHITE_LIST_RESULT.path} element={<WhitelistResult/>} />
                                {/* <Route path={PATH.LOTTERY.path} index element={<Lottery/>}/> */}
                                <Route path={PATH.SAPPHIRE.path} index element={<Sapphire/>}/>
                                <Route path={PATH.COTTON.path} index element={<Staking/>}/>
                                <Route path={PATH.RESULT_AIRDROP_ALPHA_TEST.path} element={<AirdropAlphaTestResult/>}/>
                                {/* <Route path={PATH.CLAIM_VESTING.path} element={<ClaimVesting/>}/> */}
                                {/* <Route path={PATH.PARTNER.path} element={<Partner/>}/> */}
                                {/* <Route path={PATH.TYPE_BOX_TEST.path} element={<TypeBoxTest/>}/> */}
                                
                            </Route>
                        </Route>
                        <Route path={PATH.LOGOUT.path} element={<LogOutLayout/>}></Route>
                    </Routes>
                    <Fab href='https://t.me/ninnekogame' size={isMobileBrowser() ? "small" : "medium"}
                         style={{
                             position: 'fixed',
                             bottom: 20,
                             right: 20,
                             zIndex: 999,
                             color: 'white',
                             backgroundColor: '#0088CC'
                         }}>
                        <TelegramIcon/>
                    </Fab>

                </SnackbarProvider>
              </ApolloProvider>
        </ThemeConfig>
    );
}

console.warn = () => {
}
export default App;
