import React from "react";
import {Grid} from "@mui/material";
import {Outlet} from "react-router-dom";
import HeaderBar from "../component/Header/HeaderBar";

export function TwoRowLayout() {
    return (
        <Grid container>
            <Grid item xs={12}>
                <HeaderBar/>
            </Grid>
            <Grid item xs={12}>
                <Outlet/>
            </Grid>
        </Grid>
    );
}


