import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Grid,
    InputBase,
    Link,
    Paper,
    Typography
} from "@mui/material";

import { LoadingButton } from '@mui/lab';
import { makeStyles } from "@mui/styles";
import {
    getTotalTokenVesting, getTokenAvailable, claimTokenVesting
} from "../../service/vesting";
import { useSnackbar } from "notistack";
import sessionData from '../../sessionData';

const useStyles = makeStyles((theme) => ({
    banner: {
        display: "flex",
        borderRadius: "10px",
        //background: "linear-gradient(268.58deg,#0c2a54 .69%,#1a3175)",
        //border: "1px solid #000",
        //boxSizing: "border-box",
        //boxShadow: "0 4px 4px rgba(0,0,0,.25)",
        padding: "35px 26px 28px",
        margin: "24px 0 43px",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "white",
    },
    buyBox: {
        fontSize: "16px",
    },
    buyBoxButton: {
        // marginTop: "5px"
    },
    info: {
        paddingTop: 10
    }
}));

export function ClaimVesting() {
    const classes = useStyles()

    const [totalToken, setTotalToken] = useState(0);
    const [availableToken, setAvailableToken] = useState(0);
    const [walletAddress, setWalletAddress] = useState(null);

    const getInfo = async () => {
        setWalletAddress(sessionData.myAddress);
        setTotalToken(await getTotalTokenVesting());
        setAvailableToken(await getTokenAvailable())
    };

    useEffect(() => {
        setTimeout(() => {
            getInfo();
        }, 500)
        setTimeout(() => {
            getInfo();
        }, 2000)
    }, []);

    const onError = () => {
        console.log("clean loading")
        setLoading(false)
    }

    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleClaim = async () => {
        setLoading(true);
        claimTokenVesting(enqueueSnackbar, () => {
            getInfo()
            setLoading(false)
        }, onError);
    };

    return (
        <Grid container style={{ maxWidth: "800px" }}>

            <Grid item xs={12}>
                <Card className={classes.banner}>
                    <Grid item xs={10}>
                        <Grid container>
                            <Grid item xs={12} className={classes.info}>
                                <Typography color="textPrimary" variant="h5">
                                    NINO Vesting
                                    <br />
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className={classes.info}>
                                <Grid container>
                                    <Grid item xs={5} md ={2} className={classes.buyBox}>
                                        <Typography color="textPrimary">Total NINO:</Typography>
                                    </Grid>

                                    <Grid item xs={5} md ={10} className={classes.buyBox}>
                                        <Typography color="textPrimary">{totalToken}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.info}>
                                <Grid container>
                                    <Grid item xs={5} md ={2} className={classes.buyBox}>
                                        <Typography color="textPrimary">Available:</Typography>
                                    </Grid>
                                    <Grid item xs={5} md ={10} className={classes.buyBox}>
                                        <Typography color="textPrimary">{availableToken}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={2} style={{ marginTop: 'auto', marginBottom:'auto'}}>
                        <LoadingButton onClick={handleClaim} variant="contained"
                            disabled={walletAddress == null}
                            loading={loading}
                            style={{ width: '100%', height: 40}}>
                            Claim
                        </LoadingButton>
                    </Grid>
                </Card>
            </Grid>


        </Grid>
    );
}
