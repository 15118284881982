import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Grid,
    InputBase,
    Link,
    Paper,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import CalculateIcon from '@mui/icons-material/Calculate';
import {ROIDialog} from "../../component/Misc/ROIDialog";
import {
    approveBuyNFT,
    approveContract, buyNFTBox, checkAllowanceBuyNFT,
    deposit,
    fetchPointInfo,
    fetchPoolInfo, getNumberOfNINO, harvestPool,
    POOL,
    withdrawAll
} from "../../service/staking";
import {BOX} from "../../config/staking";
import {useSnackbar} from "notistack";
import {BuyBoxDialog} from "../../component/Misc/BuyBoxDialog";
import sessionData from '../../sessionData';
import {  ConstructionOutlined, HelpOutlined } from "@mui/icons-material";
import { UnclaimedDialog } from "../../component/Misc/UnclaimedDialog";
import Countdown from 'react-countdown';

const useStyles = makeStyles((theme) => ({
    banner: {
        display: "flex",
        borderRadius: "10px",
        //background: "linear-gradient(268.58deg,#0c2a54 .69%,#1a3175)",
        //border: "1px solid #000",
        //boxSizing: "border-box",
        //boxShadow: "0 4px 4px rgba(0,0,0,.25)",
        padding: "35px 26px 28px",
        margin: "24px 0 43px",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "white",
    }

    , bannerText: {
        fontSize: "20px"
    },
    bannerLink: {
        fontSize: "14px",
        marginTop: "8px",
        marginBottom: "10px",
        lineHeight: "22px",
        color: "rgba(244,120,32,.6)"
    },
    buyBox: {
        fontSize: "16px",
        marginTop: "40px"
    },
    buyBoxButton: {
        marginTop: "5px"
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "10px"
    },
    boxImageWrapper: {
        width: "135px",
        //backgroundColor: "#183676",
        //borderRadius: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    boxImage: {
        width: "120px"
    },
    boxPoint: {
        width: "70px",
        maxWidth: "70px",
        background: "linear-gradient(270deg,#ff8c06,#f47820)",
        borderRadius: "5px",
        border: "2px solid #ff8c06",
        color: "#fff",
        fontWeight: "800",
        fontSize: "15px",
        lineHeight: "18px",
        position: "absolute",
        bottom: "-14px",
        textAlign: "center"
    },
    boxName: {
        marginTop: "24px",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "16px",
        textAlign: "center",
        color: "#3990f8"
    },
    stakingWrapper: {
        borderRadius: "10px",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
    },
    stakingRow: {
        padding: "20px 20px 10px 20px",
        borderBottom: "1px solid rgba(0,0,0,0.1)"
    },
    stakingPoolName: {
        fontFamily: "Lato-Bold,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
        fontSize: "20px",
        fontWeight: "600"
    },
    stakingLabel: {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "13px",
        fontFamily: "Lato-Regular"
    },
    stakingContent: {
        color: "black",
        fontSize: "15px",
        fontWeight: "600",
        fontFamily: "Lato-Bold,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji"
    },
    calculatorIcon: {
        verticalAlign: "bottom",
        height: "20px",
        color: "black",
        paddingBottom: "1px"
    },
    stakingDetailWrapper: {
        marginTop: "5px"
    },
    stakingDetail: {
        verticalAlign: "bottom"
    },
    stakingDetailIcon: {
        paddingBottom: "2px",
        verticalAlign: "bottom"
    },
    stakingActionWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 25px",

        //background: "rgba(208, 208, 208)"
    },
    stakingActionWrapperInner: {
        display: "flex",
        flexDirection: "row",
        width: "100%"

    },
    stakingActionWrapperAction: {
        margin: "10px",
        width: "100%"
    },
    stakingButtonWrapper: {
        marginTop: "5px",
        boxShadow: "inset 1px 2px 3px rgba(0, 0, 0, 0.075)",
        background: "rgba(244, 244, 244)"
    },
    stakingButton: {
        background: "rgb(25, 118, 210)",
        borderRadius: "30px",
    },
    withdrawButton: {
        margin: "30px 10px 0px 10px",
        borderRadius: "30px"
    },
    selected: {
        background: "rgba(200, 200, 200)"
    },
    desc: {
        fontSize: "16px",
        lineHeight: "19px"
    },
}));

export function Staking() {
    const classes = useStyles()

    // const [myNino, setMyNino] = useState(0);
    const [myPoint, setMyPoint] = useState(null);
    const [pools, setPools] = useState(POOL);
    const [walletAddress, setWalletAddress] = useState(null);

    const getInfo = async () => {
        setWalletAddress(sessionData.myAddress);
        // console.log("---1-----")
        setMyPoint(await fetchPointInfo());
        // console.log("---2-----")
        setPools(await fetchPoolInfo());
        // console.log("---3-----")
        setApproved(await checkAllowanceBuyNFT());
        // console.log("---4-----")
        // setMyNino(await getNumberOfNINO());
    };

    useEffect(() => {
        //muc dich de load info khi user vua vao trang, 1 chay sau 500s cho may nhanh, 1 chay 2000s cho may cham
        setTimeout(()=> {
            getInfo();
        }, 500)
        setTimeout(()=> {
            getInfo();
        }, 2000)
        // let myInterval = setInterval(() => {
        //     getInfo();
        // }, 20000)
        // return () => {
        //     clearInterval(myInterval);
        // };
    },[]);

    const onError = () => {
        console.log("clean loading")
        setLoading(false)
    }

    const approve = (tokenContract) => {
        setLoading(true);
        approveContract(tokenContract, enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
        },onError);
    }

    const [stakeValue, setStakeValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    // const setMaxStake = () => {
    //     setStakeValue(myNino)
    // }

    const stake = (poolId) => {
        setLoading(true);
        deposit(poolId, stakeValue, enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
        },onError);
    }

    const harvest = (poolId) => {
        setLoading(true);
        harvestPool(poolId, enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
        }, onError);
    }

    const withdraw = (poolId) => {
        setLoading(true);
        withdrawAll(poolId, enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
        }, onError);
    }

    const [openBuyBox, setOpenBuyBox] = useState(false);
    const [approved, setApproved] = useState(false);

    const approveBuyBox = () => {
        setLoading(true);
        approveBuyNFT(enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
            enqueueSnackbar("Now you can buy box", {variant: 'success', autoHideDuration: 10000});
        }, onError);
    }
    const buyBox = (boxType, numberOfBox) => {
        setOpenBuyBox(false);
        setLoading(true);
        buyNFTBox(boxType, numberOfBox, enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
            enqueueSnackbar("Buy " + parseInt(numberOfBox) + " boxes successfully!", {variant: 'success', autoHideDuration: 10000});
        }, onError);
    }


    const [openROIDailog, setOpenROIDailog] = useState(false);
    const [openUnclaimedDailog, setOpenUnclaimedDailog] = useState(false);
    const [apy, setApy] = useState(0);

    const handleClickOpenROI = (pool) => {
        setApy(132)
        // setApy(pool.apy)
        setOpenROIDailog(true);
    };

    const handleClickOpenUnclaimed = (pool) => {
        setOpenUnclaimedDailog(true);
    };

    const renderer = ({ days,hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span>Event has ended</span>;
          } else {
        
          return <span>Ends in: {days}d {hours}h {minutes}min {seconds}</span>;
        }
        
      };

    return (
        <Grid container style={{maxWidth: "800px"}}>

            <Grid item xs={12}>
                <Card className={classes.banner}>
                    <Grid item xs={12} md={4} lg={3}>
                        <div>
                        <Typography color="textPrimary" variant="h5">
                                NINO Staking
                                <br />
                               
                            </Typography>
                            <Typography color="#3990f8" variant="body2">
                            <Countdown
                                date={"Wed Dec 22 2021 09:03:14 GMT+0700"}
                                renderer={renderer}
                            />
                            </Typography>
                            <a href="https://ninneko.substack.com/p/nino-staking-guide" className={classes.bannerLink} target="_blank">
                                    Learn More</a>
                            <div className={classes.buyBox}>
                                <Typography color="textPrimary">Your point: {myPoint?myPoint:"--"}</Typography>
                                <Button className={classes.buyBoxButton} disabled={loading || walletAddress == null}
                                        onClick={() => setOpenBuyBox(true)} variant="contained">Buy Box with
                                    Point</Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8} lg={9}>
                        {/* <MHidden width={"smDown"}> */}
                        <Grid container>
                            {
                                BOX.map((box, key) => {
                                        return (
                                            <Grid item xs={6} md={6} key={key}>
                                                <div className={classes.box}>
                                                    <div className={classes.boxImageWrapper}>
                                                        <div className={classes.boxImage}>
                                                            <img src={box.boxImage}/>
                                                        </div>
                                                        <div className={classes.boxPoint}>
                                                            {box.boxPoint}
                                                        </div>
                                                    </div>
                                                    <div className={classes.boxName}>
                                                        {box.boxName}
                                                    </div>
                                                </div>
                                            </Grid>
                                        )
                                    }
                                )
                            }
                        </Grid>
                        {/* </MHidden> */}
                    </Grid>
                    {/* <Typography color="textSecondary" variant="body2"><br />You can start buying box in November 24</Typography>     */}
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Grid container className={classes.stakingWrapper}>
                    {
                        pools.map((pool, key) => {
                            return (
                                <Grid container key={key}>
                                    <Grid container className={classes.stakingRow}>
                                        {/* <Grid item xs={12} sm={2}>
                                            <span className={classes.stakingPoolName}>{pool.name}</span>
                                        </Grid> */}
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant="body2">Unclaimed Point</Typography>
                                            <div className={classes.stakingContent}>
                                                {pool.unclaim?pool.unclaim:"--"}
                                                <Link href="#">
                                                    <HelpOutlined className={classes.calculatorIcon}
                                                                   onClick={() => handleClickOpenUnclaimed(pool)}/>
                                                </Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant="body2">APY</Typography>

                                            <div className={classes.stakingContent}>
                                                {/* {pool.apy +"%"} */}
                                                {"132%"}
                                                <Link href="#">
                                                    <CalculateIcon className={classes.calculatorIcon}
                                                                   onClick={() => handleClickOpenROI(pool)}/>
                                                </Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant="body2">My Stake</Typography>
                                            <div className={classes.stakingContent}>{pool.myStake?pool.myStake:"--"}</div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant="body2">Total Stake</Typography>
                                            <div className={classes.stakingContent}>{pool.staked}</div>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={classes.stakingActionWrapper}>
                                        <div className={classes.stakingActionWrapperInner}>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <div className={classes.stakingActionWrapperAction}>
                                                        {/* <div className={classes.stakingLabel}>Enter the amount to stake</div> */}
                                                        <Typography variant="body2">Enter the amount to
                                                            stake</Typography>
                                                        <Paper className={classes.stakingButtonWrapper}
                                                               component="form"
                                                               sx={{
                                                                   p: '2px 4px',
                                                                   display: 'flex',
                                                                   alignItems: 'center',
                                                                   width: '100%'
                                                               }}
                                                        >
                                                            <InputBase
                                                                sx={{ml: 1, flex: 1}}
                                                                inputProps={{'type': 'number'}}
                                                                value={stakeValue}
                                                                onChange={event => {
                                                                    //setStakeValue(parseInt(event.target.value) <= parseInt(myNino) ? event.target.value : myNino)
                                                                    setStakeValue(event.target.value)
                                                                }}
                                                            />
                                                            {
                                                                walletAddress == null &&
                                                                <Button className={classes.stakingButton} disabled
                                                                        variant="contained">Connect your wallet to
                                                                    Stake</Button>
                                                            }
                                                            {
                                                                walletAddress != null && !pool.approved &&
                                                                <Button className={classes.stakingButton}
                                                                        disabled={loading}
                                                                        onClick={() => approve(pool.tokenContract)}
                                                                        variant="contained">Approve</Button>
                                                            }
                                                            {
                                                                // pool.approved &&
                                                                // <Button  onClick={setMaxStake}
                                                                //         variant="text">Max</Button>
                                                            }
                                                            {
                                                                pool.approved &&
                                                                <Button className={classes.stakingButton}
                                                                        onClick={() => stake(pool.poolId)}
                                                                        disabled={loading || !stakeValue}
                                                                        variant="contained">Stake</Button>
                                                            }

                                                        </Paper>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <div className={classes.stakingActionWrapperAction}>
                                                        {
                                                            // walletAddress != null &&
                                                            // <Button className={classes.withdrawButton}
                                                            //         disabled={loading}
                                                            //         onClick={() => harvest(pool.poolId)}
                                                            //         variant="contained">Claim</Button>
                                                        }
                                                        {
                                                            walletAddress != null &&
                                                            <Button className={classes.withdrawButton}
                                                                    disabled={loading}
                                                                    onClick={() => withdraw(pool.poolId)}
                                                                    variant="contained">Withdraw</Button>
                                                        }
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
            <Typography variant="body2" style={{paddingTop: 16}}>
                *To avoid low APY, we will stop receiving NINO tokens for staking after 01 PM December 01, 2021 (UTC).
                If you withdraw your NINO after that time, you won't be able to stake your tokens again
            </Typography>
            <ROIDialog apy={apy} open={openROIDailog} onClose={() => setOpenROIDailog(false)}/>
            <UnclaimedDialog  open={openUnclaimedDailog} onClose={() => setOpenUnclaimedDailog(false)}/>
            <BuyBoxDialog approved={approved} approveBuyNFT={approveBuyBox} buyNFTBox={buyBox} loading={loading}
                          open={openBuyBox} onClose={() => setOpenBuyBox(false)}/>
        </Grid>
    );
}


