import { useTheme } from '@emotion/react';
import MenuIcon from '@mui/icons-material/Menu';
import {
    AppBar, Button, IconButton, Toolbar, useMediaQuery
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useState } from 'react';
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import { mainMenu } from '../../config/menu';
import { PATH } from "../../config/routes";
import sessionData from '../../sessionData';
import { MHidden } from '../@material-extend';
import ConnectWalletBox from '../Login/ConnectWalletBox';
import MobileSidebar from '../SideBar/MobileSidebar';
import UserInfo from './UserInfo';




const useStyles = makeStyles((theme) => ({
    appBar: {
        backdropFilter: 'blur(6px)',
        WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
        backgroundColor: alpha(theme.palette.background.default, 0.52),
        zIndex: theme.zIndex.drawer + 100,
    },
    menuButton: {
        //color: 0,
        //marginRight: theme.spacing(2),
        marginLeft: 'auto',
        //float: 'right',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            height:42,
            lineHeight:1.3,
        },
    },
    nav: {
        display: 'flex',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },

    },

    leftNav: {
        flexGrow: 1,
        display: 'inline-flex',
    },
    rightNav: {
        //marginRight: 50,
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    navLink: {
        marginRight: theme.spacing(3),
        color: "inherit",
        display: "inline-flex",
        textDecoration: 'none'

    },
    icon: {
        width: 20,
        height: 20
    },
    logo: {
        textDecoration: 'none',
        marginRight: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center'
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,


}));

export function ToolBarPlaceHolder() {
    const classes = useStyles();
    return <div className={classes.toolbar} />

}


export const Logo = ({ classes }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    return (

        <Link to={PATH.HOME.path} className={classes.logo}>
            {
                matches ?
                    <img src="/images/svg/coin_nino.svg" alt="Ninneko" style={{height: 42 }} /> :
                    <img src="/images/lg_169x47.png" alt="Ninneko" style={{ width: 169, height: 47 }} />
            }
        </Link>
    )

}


export default function HeaderBar({ inLoginPage = false }) {
    

    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [address, setAddress] = useState(null);
    const [openNetworkDialog, setOpenNetworkDialog] = useState(false);
    const navigate = useNavigate();

    // const connectToWallet = async () => {
    //     var web3 = await connectWallet();
    //     if (web3 != null) {
    //         var walletAddress = getWalletAddress();
    //         setAddress(walletAddress.substr(0, 6) + "..." + walletAddress.substr(walletAddress.length - 4, 4));

    //         var chainId = await getChainId();
    //         if (chainId != NETWORK_VERSION) {
    //             setOpenNetworkDialog(true);
    //         }
    //     }
    // }

    

    // const handleChangeNetwork = async () => {
    //     await switchToBSCNetwork(navigate);
    //     setOpenNetworkDialog(false);
    // }

    // connectToWallet()

    return (
        <Fragment>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Logo classes={classes} />
                    <div className={classes.nav}>
                        <div className={classes.leftNav}>
                            {/* {
                                mainMenu().map((prop, key) =>
                                    !prop.disabled && <Link to={prop.link} className={classes.navLink} key={key}>
                                        <Button
                                            startIcon={<prop.icon className={classes.icon} />}>{prop.name}</Button>
                                    </Link>
                                )
                            } */}
                        </div>
                        {
                            !inLoginPage && < div className={classes.rightNav}>
                                {
                                    sessionData.myAddress != null ?
                                        // <UserInfo address={address} />
                                        // :
                                        // <MHidden width='smDown'>
                                        //     <Button
                                        //         //className={classes.menuButton}
                                        //         // size="medium"
                                        //         onClick={() => connectToWallet()}
                                        //         variant="contained"
                                        //         color="primary">
                                        //         Connect Wallet
                                        //     </Button>
                                        // </MHidden>
                                        <UserInfo address={sessionData.myAddress} />
                                        :
                                        <MHidden width='smDown'>

                                            <ConnectWalletBox
                                                component={<Button
                                                    //className={classes.menuButton}
                                                    // size="medium"
                                                    variant="contained"
                                                    color="primary">
                                                    Connect Wallet
                                                </Button>} />
                                        </MHidden>
                                }
                            </div>
                        }
                    </div>
                    {/* {
                        //Sau này nếu có nhiều route mà ko cần connect vẫn vào được, thì hiện cả nút connect lẫn menu
                        //thu cái logo lại, bỏ chữ ninneko đi
                        sessionData.myAddress != null ?
                            <IconButton
                                color="default"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                            :
                            <ConnectWalletBox
                                component={<Button
                                    className={classes.menuButton}
                                    // size="small"
                                    variant="contained"
                                    color="primary">
                                    Connect Wallet
                                </Button>} />
                    } */}

                    {
                        //Sau này nếu có nhiều route mà ko cần connect vẫn vào được, thì hiện cả nút connect lẫn menu
                        //thu cái logo lại, bỏ chữ ninneko đi
                        <Fragment>
                            {sessionData.myAddress == null &&
                                <ConnectWalletBox
                                component={<Button
                                    className={classes.menuButton}
                                    // size="small"
                                    variant="contained"
                                    color="primary">
                                    Connect Wallet
                                </Button>} />
                            }
                            <IconButton
                                color="default"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleDrawerToggle}
                                className={classes.menuButton}
                                style={sessionData.myAddress == null? {marginLeft:5} : {marginLeft:"auto"}}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Fragment>
                    }

                </Toolbar>
            </AppBar>
            <div className={classes.toolbar} />


            {/* Sidbar tren man hinh mobile */}
            <MobileSidebar login={(sessionData.myAddress != null)}
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle} />
            
            {/* <ChangeNetworkDialog open={openNetworkDialog} onClose={() => setOpenNetworkDialog(false)} handleChangeNetwork={handleChangeNetwork} /> */}
        </Fragment>
    )
}




