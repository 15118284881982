// @ts-nocheck
//dang dung

import Web3 from 'web3';
import sessionData from '../sessionData';
import { NETWORK_RPC_URL, NETWORK_CONFIG, NETWORK_VERSION_HEX, METAMASK_PROVIDER, WALLETCONNECT_PROVIDER, NETWORK_VERSION } from './constants';
import { API_RANDOM_STRING, linkToHome } from './link'
import WalletConnectProvider from "@walletconnect/web3-provider";
import {sendAdRefWhenConnectWallet} from "../../src/util/utils"

// const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
var web3 = null
var provider = null
var providerType = null    

var jwt

export const updateWeb3Provider = (p) => {
    //console.log('update Provider:', p)
    if (p == METAMASK_PROVIDER) {
        providerType = METAMASK_PROVIDER
        provider = window.ethereum
        web3 = new Web3(provider);
    }
    if (p == WALLETCONNECT_PROVIDER) {
        providerType = WALLETCONNECT_PROVIDER
        provider = new WalletConnectProvider({
            //infuraId: '5f74127583e24c7d83c33a14811b2dfb',
            // chainId:97,
            rpc: {
                //97: "https://data-seed-prebsc-1-s1.binance.org:8545",
                56: "https://bsc-dataseed.binance.org"
            },
            chainId: 56,
            network: "binance",
        });
        web3 = new Web3(provider);
    }

    //console.log(web3)
}

export const initWallet = async () => {
    sessionData.provider && updateWeb3Provider(sessionData.provider)

    if (provider && providerType == WALLETCONNECT_PROVIDER) {
        console.log(provider.wc.connected)
        if (!provider.wc.connected) {
            sessionData.logOut()
        } else {
            if (!sessionData.isLoggedIn()) {
                await provider.disconnect()
            } else {
                await provider.enable()
            }
        }
    }

    if (provider && providerType == METAMASK_PROVIDER) {
        await provider.enable()
    }
}


export const getWeb3 = () => {
    return web3
}

export const getProviderType = () => {
    return providerType
}

export const getWalletAddressAsync = async () => {
    try {
        // const accounts = await web3.currentProvider.request({ method: 'eth_accounts' })
        if (web3) {
            const accounts = await web3.eth.getAccounts();         
            const myAddress = accounts[0]
            if (myAddress) { //đã connect
                console.log('Wallet connected, address: ' + myAddress)
                sessionData.updateAddress(myAddress)
                sessionData.updateProvider(providerType)
                return myAddress
            } else {
                console.log('Wallet is not connected')
            }
        }
    } catch (error) {
        console.error(error);
    }

    return null


}

/**
 * Cứ đọc address từ cookie trước, trong khi đó hàm getWalletAddressAsync sẽ chạy và update lại giá trị 
 * address cho đúng
 * @returns 
 */

export const getWalletAddress = () => {

    //chua lay duoc address, cố gọi thử để lần sau gọi vào sẽ lấy được, lần đầu chấp nhận lỗi
    if (!sessionData.myAddress) {
        //console.log("Try to get MetaMask address");
        return getWalletAddressAsync()
    }
    // console.log("Wallet address was found:",sessionData.myAddress);
    return sessionData.myAddress
}

export const switchToBSCNetwork = async (myHistoty = null) => {
    try {
        console.log('---')
        await web3.currentProvider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: NETWORK_VERSION_HEX }],
        });
        console.log('---done')
        //nếu truyền history vào thì refresh, chỉ làm 1 lần tránh lặp vô tận
        if (myHistoty) {
            myHistoty.push(linkToHome);
            myHistoty.goBack();
        }
    } catch (switchError) {
        console.log(switchError.code)
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
            console.log("Chain was not added to Metamask")
            try {
                await web3.currentProvider.request({
                    method: 'wallet_addEthereumChain',
                    params: NETWORK_CONFIG,
                });
            } catch (addError) {
                // handle "add" error
                console.log("Error adding Chain to Metamask")
                console.log(addError)
            }
        }
        // handle other "switch" errors
    }
}

export const getNetworkVersion = () => {
    if (!web3 || !web3.currentProvider) return -1000000
    return providerType === METAMASK_PROVIDER ? web3.currentProvider.networkVersion : web3.currentProvider.chainId
}

export const getJWT = (dataForApi) => {
    // bản này không cần JWT, chỉ kết nối tới chain
    //requestConnectWallet(dataForApi);

    (async () => {
        

            requestConnectWallet(dataForApi);

        

        //console.log("JWT " + jwt)
    })();

}

const getWalletAccounts = async (dataForApi) => {

    const myAddress = await getWalletAddressAsync()

    if (myAddress) { //đã connect
        console.log('Đã kết nối, địa chỉ: ' + myAddress)
        // requestSignAndRegister(myAddress)
        sessionData.setSessionData(null, null, myAddress, providerType)
        dataForApi.addr = myAddress;
        await sendAdRefWhenConnectWallet(dataForApi)
        window.location = linkToHome
        
    } else {
        console.log('Chưa kết nối')
    }
}

export const requestConnectWallet = async (dataForApi) => {
    try {
        await web3.currentProvider.enable()
        getWalletAccounts(dataForApi)
    } catch (error) {
        console.error(error);
    }
}