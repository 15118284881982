import React from "react";
import {Outlet} from "react-router-dom";
import {Grid} from "@mui/material";
import {MHidden} from "../component/@material-extend";
import {sideBarMenu} from "../config/menu";
import WebSidebar from "../component/SideBar/WebSidebar";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    main: {
        padding: "15px"
    },
}));

export function TwoColumnLayout() {
    const classes = useStyles()

    return (
        <Grid container>
            <Grid item md={2}>
                <MHidden width='mdDown'>
                    <WebSidebar routes ={sideBarMenu()}/>
                </MHidden>
            </Grid>
            <Grid item sm={12} md={10} className={classes.main}>
                <Outlet/>
            </Grid>
        </Grid>
    );
}


