import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Grid,
    InputBase,
    Link,
    Paper,
    Typography
} from "@mui/material";

import { PATH } from "../../config/routes";
import { makeStyles } from "@mui/styles";
import CalculateIcon from '@mui/icons-material/Calculate';
import { WhitelistROIDialog } from "../../component/Misc/WhitelistROIDialog";
import {approveBuyNFT, buyNFTBox, checkAllowanceBuyNFT, checkSoldOut} from "../../service/sapphire";
import { BOX_SAPPHIRE } from "../../config/staking";
import { useSnackbar } from "notistack";
import sessionData from '../../sessionData';
import { HelpOutlined } from "@mui/icons-material";
import Countdown from 'react-countdown';
import { BuySapphireBoxDialog } from "../../component/Misc/BuySapphireBoxDialog";
// import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    banner: {
        display: "flex",
        borderRadius: "10px",
        //background: "linear-gradient(268.58deg,#0c2a54 .69%,#1a3175)",
        //border: "1px solid #000",
        //boxSizing: "border-box",
        //boxShadow: "0 4px 4px rgba(0,0,0,.25)",
        padding: "35px 26px 28px",
        margin: "24px 0 43px",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "white",
    },
    adBanner: {
        display: "flex",
        borderRadius: "10px",
        background: "#1976d2", //theme.palette.primary.main , // "linear-gradient(268.58deg,#0c2a54 .69%,#1a3175)",
        // border: "1px solid #000",
        // boxSizing: "border-box",
        // boxShadow: "0 4px 4px rgba(0,0,0,.25)",
        padding: "35px 26px 28px",
        margin: "24px 0 43px",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "white",
    }

    , bannerText: {
        fontSize: "25px"
    },
    bannerLink: {
        fontSize: "16px",
        marginTop: "8px",
        marginBottom: "10px",
        lineHeight: "22px",
        color: "rgba(244,120,32,.6)"
    },
    buyBox: {
        fontSize: "16px",
        marginTop: "20px"
    },
    buyBoxButton: {
        marginTop: "5px",
        backgroundColor: "#1976d2"
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    boxImageWrapper: {
        width: "180px",
        //backgroundColor: "#183676",
        //borderRadius: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    boxImage: {
        width: "180px"
    },
    boxPoint: {
        width: "70px",
        maxWidth: "70px",
        background: "linear-gradient(270deg,#ff8c06,#f47820)",
        borderRadius: "5px",
        border: "2px solid #ff8c06",
        color: "#fff",
        fontWeight: "800",
        fontSize: "15px",
        lineHeight: "18px",
        position: "absolute",
        bottom: "-14px",
        textAlign: "center"
    },
    boxName: {
        marginTop: "24px",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "16px",
        textAlign: "center",
        color: "#3990f8"
    },
    stakingWrapper: {
        borderRadius: "10px",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
    },
    stakingRow: {
        padding: "20px 20px 10px 20px",
        borderBottom: "1px solid rgba(0,0,0,0.1)"
    },
    stakingPoolName: {
        fontFamily: "Lato-Bold,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
        fontSize: "20px",
        fontWeight: "600"
    },
    stakingLabel: {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "13px",
        fontFamily: "Lato-Regular"
    },
    stakingContent: {
        color: "black",
        fontSize: "15px",
        fontWeight: "600",
        fontFamily: "Lato-Bold,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji"
    },
    calculatorIcon: {
        verticalAlign: "bottom",
        height: "20px",
        color: "black",
        paddingBottom: "1px"
    },
    stakingDetailWrapper: {
        marginTop: "5px"
    },
    stakingDetail: {
        verticalAlign: "bottom"
    },
    stakingDetailIcon: {
        paddingBottom: "2px",
        verticalAlign: "bottom"
    },
    stakingActionWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 25px",

        //background: "rgba(208, 208, 208)"
    },
    stakingActionWrapperInner: {
        display: "flex",
        flexDirection: "row",
        width: "100%"

    },
    stakingActionWrapperAction: {
        margin: "10px",
        width: "100%"
    },
    stakingButtonWrapper: {
        marginTop: "5px",
        boxShadow: "inset 1px 2px 3px rgba(0, 0, 0, 0.075)",
        background: "rgba(244, 244, 244)"
    },
    stakingButton: {
        background: "rgb(25, 118, 210)",
        borderRadius: "30px",
    },
    withdrawButton: {
        margin: "30px 10px 0px 10px",
        borderRadius: "30px"
    },
    selected: {
        background: "rgba(200, 200, 200)"
    },
    desc: {
        fontSize: "16px",
        lineHeight: "19px"
    },
}));

export function Sapphire() {
    const classes = useStyles()

    // const [myNino, setMyNino] = useState(0);
    const [walletAddress, setWalletAddress] = useState(null);

    const getInfo = async () => {
        setWalletAddress(sessionData.myAddress);
        setApproved(await checkAllowanceBuyNFT());
        setIsSoldOut(await checkSoldOut())
        // setMyNino(await getNumberOfNINO());
    };

    useEffect(() => {
        //muc dich de load info khi user vua vao trang, 1 chay sau 500s cho may nhanh, 1 chay 2000s cho may cham
        setTimeout(() => {
            getInfo();
            // getTime();
        }, 500)
        setTimeout(() => {
            getInfo();
            // getTime();
        }, 2000)
        // let myInterval = setInterval(() => {
        //     getInfo();
        // }, 20000)
        // return () => {
        //     clearInterval(myInterval);
        // };
    }, []);

    const onError = () => {
        console.log("clean loading")
        setLoading(false)
    }


    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [openBuyBox, setOpenBuyBox] = useState(false);
    const [approved, setApproved] = useState(false);
    const [isSoldOut, setIsSoldOut] = useState(true);

    const approveBuySapphireBox = () => {
        setLoading(true);
        approveBuyNFT(enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
            enqueueSnackbar("Now you can buy box", { variant: 'success', autoHideDuration: 10000 });
        }, onError);
    }
    const buyBox = (numberOfBox) => {
        setOpenBuyBox(false);
        setLoading(true);
        buyNFTBox(numberOfBox, enqueueSnackbar, () => {
            getInfo();
            setLoading(false);
            enqueueSnackbar("Buy " + parseInt(numberOfBox) + " boxes successfully!", { variant: 'success', autoHideDuration: 10000 });
        }, onError);
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span></span>;
        } else {

            return <span>Starts in: {days}d {hours}h {minutes}min {seconds}</span>;
        }

    };

    return (
        <Grid container style={{ maxWidth: "800px" }}>
            <Grid item xs={12}>
                <Card className={classes.adBanner}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Typography color="white" variant="h3">
                            Sapphire NFT Box
                        </Typography>
                        {/* <Typography color="white" variant="body1">
                            <Countdown
                                date={"Dec 28 2021 20:00:00 GMT+0700"}
                                renderer={renderer}
                            />
                        </Typography> */}
                        <Typography color="white">
                            <br />
                            Inside the box: 4% Wild, 6% Alpha, 90% standard NFT Ninneko &nbsp;
                            <a href="https://t.me/ninnekoann" 
                                //className={classes.bannerLink} 
                                style={{color: 'white'}}
                                target="_blank">
                                Learn more
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Grid container style={{justifyContent: 'center'}}>
                            <img src="/images/char3.png" />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.banner}>
                    <Grid item xs={12} md={6} lg={6}>
                        <div>
                            <Typography color="textPrimary" variant="h5">
                                Price: 999 NINO
                                <br />
                            </Typography>
                            <a href="https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x6CAD12b3618a3C7ef1FEb6C91FdC3251f58c2a90" 
                                className={classes.bannerLink} target="_blank">
                                Buy NINO on PancakeSwap
                            </a>
                            <div className={classes.buyBox}>
                                <Button className={classes.buyBoxButton} disabled={loading || walletAddress == null || isSoldOut}
                                    onClick={() => setOpenBuyBox(true)} variant="contained" size="large">{isSoldOut ? "Sold Out" : "Buy Sapphire Box"}</Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        {/* <MHidden width={"smDown"}> */}
                        <Grid container style={{justifyContent: 'center'}}>
                            {
                                BOX_SAPPHIRE.map((box, key) => {
                                    return (
                                        <Grid item xs={6} md={6} key={key}>
                                            <div className={classes.box}>
                                                <div className={classes.boxImageWrapper}>
                                                    <div className={classes.boxImage}>
                                                        <img src={box.boxImage} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    )
                                }
                                )
                            }
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <BuySapphireBoxDialog approved={approved} approveBuyNFT={approveBuySapphireBox} buyNFTBox={buyBox} loading={loading}
                          open={openBuyBox} onClose={() => setOpenBuyBox(false)}/>
        </Grid>
    );
}


