import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import RedeemIcon from '@mui/icons-material/Redeem';
import ArchiveIcon from '@mui/icons-material/Archive';
import {DiamondOutlined, FormatListNumbered, Inventory2Outlined, LogoutOutlined, StyleOutlined, ViewInArOutlined} from "@mui/icons-material";
import sessionData from "../sessionData";
import {PATH} from "./routes";

const MENU = {
    staking: {
        name: "Staking",
        icon: AccountBalanceIcon,
        link: PATH.STAKING.path,
        child: [
            {
                name: "Sapphire Box",
                icon: ViewInArOutlined,
                link: PATH.SAPPHIRE.path,
                // disabled: true
            },
            {
                name: "Cotton Box",
                icon: Inventory2Outlined,
                link: PATH.COTTON.path,
            },
            {
                name: "Ruby Box",
                icon: DiamondOutlined,
                link: PATH.WHITE_LIST.path,
                // disabled: true
            },
            // {
            //     name: "Lottery",
            //     icon: StyleOutlined,
            //     link: PATH.LOTTERY.path,
            //     // disabled: true
            // },
            {
                name: "White List Ranking",
                icon: StyleOutlined,
                link: PATH.WHITE_LIST_RANKING.path,
                disabled: true
            },
            {
                name: "My Box",
                icon: RedeemIcon,
                link: PATH.STAKING_ME.path,
                // disabled: sessionData.myAddress == null
            },
            {
                name: "Vesting",
                icon: ArchiveIcon,
                link: PATH.CLAIM_VESTING.path,
                disabled: true
            },
        ]
    },
    account: {
        name: "Account",
        icon: DashboardIcon,
        link: PATH.ACCOUNT.path,
        disabled: true//!sessionData.isLoggedIn()
    }
}

export const loginMenu = () => [
    {
        name: "Disconnect",
        icon: LogoutOutlined,
        link: PATH.LOGOUT.path
    }
]

export const mobileMenu = () => 
    [
        ...MENU.staking.child,
        MENU.account
    ]




//hàm trả về menu theo page hiện tại
export const mainMenu = () => [
    MENU.staking,
    MENU.account
];

//hàm trả về sidebar menu theo page hiện tại
export const sideBarMenu = () => MENU.staking.child
